import React from 'react'

import Layout from '../../layouts'
import Grid from '../../components/elements/grid'
import { Body, SizedText } from '../../components/elements/typography'
import Icon from '../../components/elements/icon'
import { Link } from 'gatsby'

import checkIcon from '../../theme/icons/green-check.svg'

const RSVPSuccessPage = () => {

  return (
    <Layout isSubMenuOpen={false} forceRootMenu="/">
      <Grid.FullWidthSection css={{padding: `6rem 0`, alignItems: `center`}}>
        <Grid.Block start={[2,2,2,2,3,4]} span={[12,12,12,12,10,8]} css={{backgroundColor: `#fff`, overflow: `hidden`}}>
          <div css={{display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 1fr`, 'div': {height: `0.702rem`},}}>
            <div css={{backgroundColor: `#00b4e5`}}></div><div css={{backgroundColor: `#0376bd`}}></div><div css={{backgroundColor: `#005ea8`}}></div><div css={{backgroundColor: `#192851`}}></div>
          </div>


          <div css={{ 
            padding: `4rem 2rem`,
            '@media (min-width: 1024px)': {
              padding: `4rem`
            }
          }}>
            <SizedText as="h1" smallSize="5.824" largeSize="9.373" css={{textAlign: `center`, marginBottom: `3rem`}}>
              <Icon icon={checkIcon} overrides={{verticalAlign: `-0.65rem !important`}}/>
            </SizedText>
            <SizedText as="h1" smallSize="1.802" largeSize="3.653" css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, marginBottom: `2rem`}}>
              We got it! Thank you for letting us know! 
            </SizedText>
            <Body>
              If you decide to change your reservation status, please let us know by submitting the RSVP form again or by emailing Mary, at <a href='mailto:mfuller@tcbg.bank'>mfuller@tcbga.bank</a>. <br /><br />
            </Body>

            <Link to="/events/guns-and-funds-dinner">Go Back to Event Page</Link>
          </div>

          <div css={{display: `grid`, gridTemplateColumns: `1fr 1fr 1fr 1fr`, 'div': {height: `0.702rem`},}}>
            <div css={{backgroundColor: `#00b4e5`}}></div><div css={{backgroundColor: `#0376bd`}}></div><div css={{backgroundColor: `#005ea8`}}></div><div css={{backgroundColor: `#192851`}}></div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}

export default RSVPSuccessPage

